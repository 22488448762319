<template>
  <html lang="en" class="notranslate" translate="no"><!-- All translators -->
    <head>
      <meta name="google" content="notranslate" /> <!-- Just for google -->
    </head>
    <div class="app">
      <Menu :list="menuList"/>

      <div class="">
          <body>    

            <!-- <PanelInformativo id="panel-informativo" name="panel-informativo"/> -->
            <Slider />

            <div>
                <QuienesSomos id="quienes-somos" name="quienes-somos"/>
              
                <MisionVision id="mision-vision" name="mision-vision"/>
              
                <Valores id="valores" name="valores"/>
          
                <Ventajas id="ventajas" name="ventajas" />
              
                <Beneficios id="beneficios" name="beneficios"/>
                
                <SimuladorAhorro id="simulador-ahorro" name="simulador-ahorro"/>
              
                <Tramite id="tramite" name="tramite"/>
          
                <ComoFunciona id="como-funciona" name="como-funciona"/>
              
              <a name="comparacion"></a>
                <Comparacion/>

              <a name="savings-for_minors"></a>
                <SavingsForMinors/>
              
                <CarucelNoticias id="carucel-noticias" name="carucel-noticias"/>
          
                <Colaboradores id="colaboradores" name="colaboradores"/>
              
              <div name="footer">
                <Footer/>
              </div>
                
            </div>
          </body>
      </div>
    </div>
    
  </html>
        
</template>

<script>
import { ref, onBeforeMount} from 'vue';
import PanelInformativo from '@/components/PanelInformativo1.vue'
import QuienesSomos from '@/components/QuienesSomos.vue'
import MisionVision from '@/components/MisionVision.vue'
import Valores from '@/components/Valores.vue'
import Ventajas from '@/components/Ventajas.vue'
import Beneficios from '@/components/Beneficios.vue'
import SimuladorAhorro from '@/components/SimuladorAhorro.vue'
import Tramite from '@/components/Tramite.vue'
import ComoFunciona from '@/components/ComoFunciona.vue'
import Comparacion from '@/components/Comparacion.vue'
import CarucelNoticias from '@/components/CarucelNoticias.vue'
import Colaboradores from '@/components/Colaboradores.vue'
import Footer from '@/components/Footer3.vue'
import Menu from '@/components/Menu.vue'
import SavingsForMinors from "@/components/SavingsForMinors.vue"

import Slider from "@/components/Slider.vue";
import menuOptions  from "@/helpers/menu.js";

export default {
  name: 'Home',
  setup(){

    let menuList = ref([]);

    const errors= ref([]);
    const rfc= ref("");
    const paternal= ref("");
    const maternal= ref("");
    const names= ref("");
    const cellphone= ref("");
    const email= ref("");
    const fullNameContact= ref("");
    const cellphoneContact= ref("");
    const numberContact= ref("");
    const mensaje= ref("");

    onBeforeMount(async () => {
      await getMenuOptionsList();
    });

    const getMenuOptionsList = async () => {
      await menuOptions.getMenuOptions().then(resp => {
        menuList.value = resp.data.FONCABSA;
      }).catch(err => {
        console.log("🚀 ~ file: Home.vue:223 ~ getMenuOptions ~ err", err);
      });
    };

    return {
      menuList,
      errors,
      rfc,
      paternal,
      maternal,
      names,
      cellphone,
      email,
      fullNameContact,
      cellphoneContact,
      numberContact,
      mensaje
    }
  },
  components: {
    Menu,
    PanelInformativo,
    QuienesSomos,
    MisionVision,
    Valores,
    Ventajas,
    Beneficios,
    SimuladorAhorro,
    Tramite,
    ComoFunciona,
    Comparacion,
    CarucelNoticias,
    Colaboradores,
    SavingsForMinors,
    Footer,
    Slider
  }
}
</script>

<style scoped>
  header {
    background: rgba(0,0,0,0.9);
    width: 100%;
    position: fixed;
    z-index: 100;
  }
  .texto-encima{
      position: absolute;
      top: 10px;
      left: 10px;
  }
  #button{
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;        
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      font-weight: bold;
  }
  html,body{
    overflow-x: hidden;
    color:black;
    font-family:'Opens Sans',helvetica;  
    height:100%;
    margin: 0px;
    padding: 0px;
  }

  #button_popups{
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        background-color: #FEB72B;
        width: 250px;
        height: 30px;
        padding-top: 0%;
    }


/* popup de registro */
  .window-register{
      background: white;
      width: 80%;
      color:rgba(255, 255, 255, 1);
      font-family: Arial, Helvetica, sans-serif;
      font-size: 18px;
      text-align: center;
      padding: 33px;
      min-height: 250;
      border-radius: 10px;
      position: absolute;
      left:10%;
      top: 10%;
      display:none;
      height: auto;
  }

  #closeWindowRegister{
    position: absolute;
    right: 10px;
    top:10px;
  }
  /* popup de registro */

  /* popup de registro */
  .window-contact{
      background: white;
      width: 50%;
      color:rgba(255, 255, 255, 1);
      font-family: Arial, Helvetica, sans-serif;
      font-size: 18px;
      text-align: center;
      padding: 33px;
      min-height: 240;
      border-radius: 10px;
      position: absolute;
      left:25%;
      top: 10%;
      display:none;
      height: 580px;
  }

  #closeWindowContact{
    position: absolute;
    right: 10px;
    top:10px;
  }
  /* popup de registro */

  button {outline: none !important;}

  .content-footer{
    /* background: #821740; */
    margin-left:10% ;
    margin-right:10% ;
  }
</style>
