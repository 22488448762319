<template>
    <div>
        <Carousel :autoplay="5000" :wrap-around="true" @page-change="changeImageDefault()" :transition="300">

            <slide key="1">
                <router-link to="/registro">
                    <div class="w-screen animate-fade">
                        <img src="@/assets/portada.png" style="min-height: 240px;margin: auto;width: -webkit-fill-available;" class="w-screen bg-image-slide" loading="lazy">
                        <div class="centered" style="">
                            <p class="mainTitle text-base md:text-xl lg:text-4xl mb-3 font-light"><b class="font-semibold">FONCABSA</b> es una sociedad cooperativa de ahorro y préstamo, que
                                ofrece servicios financieros a tasas competitivas</p>
                            <p class="mainTitle hidden md:block text-sm md:text-base lg:text-xl xl:text-2xl font-light">Estamos preparados para brindarte soluciones financieras<br> de calidad, que funcionan para cualquier
                            emergencia, <br>para nosotros lo más importante eres tú y tu familia
                            </p>
                        </div>
                    </div>
                </router-link>
            </slide>

            <slide key="2">
                <router-link to="/sinaptissste">
                    <div class="w-screen animate-fade">

                        <img src="@/assets/SINAPTISSSTE/seguro-suficiente.png" alt="seguro-suficiente" class="w-screen bg-image-slide" loading="lazy">
                        <div class="centered-item my-5 lg:my-12">
                            <div class="grid grid-cols-3">
                                <div class="mx-auto">
                                    <img src="@/assets/SINAPTISSSTE/logo-SINAPTISSSTE.png" alt="SINAPTISSSTE" class="image-slide-size" loading="lazy">
                                </div>
                                
                                <div class="col-span-2 mx-auto py-2 lg:py-16 text-right">
                                    <p class="text-base md:text-3xl lg:text-4xl mb-3 text-white font-light">Si eres parte de <b>SINAPTISSSTE</b> ya puedes formar parte de <b>FONCABSA</b> de forma rápida</p>
                                </div>
                            </div>
                            

                        </div>
                    </div>
                </router-link> 

                
            </slide>


            <template #addons>
                <Navigation />
                <Pagination />
            </template>
        </Carousel>
    </div>
</template>
<script>
import { toRef } from "vue";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import 'vue3-carousel/dist/carousel.css';

export default {
    name: "sliderComponent",
    props: {
        items: {
            type: Array,
            default: [
                { name: "ITEM 1" }
            ]
        }
    },
    setup(props){
        const slideList = toRef(props, "items");
        return {
            list: slideList
        }
    },
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation
    }
}
</script>

<style>
.carousel__prev {
    left: 35px !important;
}
.carousel__next {
    right: 35px !important;
}
.carousel__prev, .carousel__next {
    background-color: transparent !important;
    width: 50px;
    height: 50px;
    color: #810042;
}
.carousel__icon {
    width: 2.5em;
    height: 2.5em;
}
.carousel__pagination-item > button {
    width: 15px;
    height: 15px;
    border-radius: 10px;
    background-color: #E5A800 !important;
}
.carousel__pagination {
    padding-top: 11px;
}
.centered-item {
    position: absolute;
    top: 45%;
    left: 23%;
    transform: translate(-20%, -50%);
    font-family: Roboto;
    text-align: right;
    width: 90%;
}
.image-slide-size {
    width: 22vw;
    margin-top: 20px;
}
.bg-image-slide {
    height: 75vh;
}
.carousel__prev, .carousel__next {
    top: 45%!important;
}
@media (min-width: 200px) and (max-width: 600px) {
    .carousel__prev, .carousel__next {
        top: 40%!important;
    }
    .bg-image-slide {
        height: 65vw;
    } 
}
@media (min-width: 600px) and (max-width: 900px) {
    .bg-image-slide {
        height: 40vw;
    } 
}
@media (min-width: 900px) and (max-width: 1024px) {
    .bg-image-slide {
        height: 45vw;
    } 
}
</style>